import { createContext } from 'react'
import { MatomoEventTracker } from '@hrk/types'

export interface IMatomoTrackerContext {
  matomoTracker: MatomoEventTracker | undefined
}
const defaultContext: IMatomoTrackerContext = {
  matomoTracker: undefined,
}
export const MatomoTrackerContext = createContext<IMatomoTrackerContext>(defaultContext)
