import { Wit, WitData } from '@hrk/wit-component-library'
import React, { useEffect, useState } from 'react'
import {
  useCreateUserCodeMutation,
  useWitCreateMutation,
  useWitQuery,
  Wit as WitModel,
  WitInput,
} from 'frontendApiGraphqlTypes'
import { useUserCodeContext } from '../../context/UserCodeContext'
import { useLayoutContext } from '../../hooks/useLayoutContext'
import { MatomoEventTracker } from '@hrk/types'

// TODO: check why this is imported like that and not like from '@hrk/wit-component-library'
import { WitResultData } from '@hrk/wit-component-library/build/Wit/Wit.types'
import { navigateWithParams } from '../../utils/navigateWithParams'
import { localizedSlug } from '../../utils/localizedSlug'
import { LoadingSpinner } from '@hrk/huw-module-library'
import { MatomoTrackerContext } from '../../context/MatomoTrackerContext'
import { CookieContext } from '../../context/CookieContext'

interface WitComponentValues {
  values: {
    R: number
    I: number
    A: number
    S: number
    E: number
    C: number
  }
  socials: {
    gender: string
    age: string
    state: string
    useas: string
    education: string
    job: string
    reason: string
    interest: string
  }
  shortCode: string
}

const defaultWit: WitComponentValues = {
  values: { R: 0, I: 0, A: 0, S: 0, E: 0, C: 0 },
  socials: { gender: '', age: '', state: '', useas: '', education: '', job: '', reason: '', interest: '' },
  shortCode: '',
}

function mapToWitComponentValues({ values, socials, shortCode }: WitModel): WitComponentValues {
  return {
    values: { R: values.r, I: values.i, A: values.a, S: values.s, E: values.e, C: values.c },
    socials: {
      gender: `${socials.gender}`,
      age: `${socials.age}`,
      state: `${socials.state}`,
      useas: `${socials.useas}`,
      education: `${socials.education}`,
      job: `${socials.job}`,
      reason: `${socials.reason}`,
      interest: `${socials.interest}`,
    },
    shortCode,
  }
}

function mapToWitInput(witData: WitData, userCode: string): WitInput {
  const { socials, values } = witData
  return {
    shortCode: witData.shortCode,
    values: {
      r: values.R,
      i: values.I,
      a: values.A,
      s: values.S,
      e: values.E,
      c: values.C,
    },
    socials: {
      gender: socials.gender,
      age: socials.age,
      state: socials.state,
      useas: socials.useas,
      education: socials.education,
      job: socials.job,
      reason: socials.reason,
      interest: socials.interest,
    },
    userCode,
  }
}

const WitWrapper = (): JSX.Element => {
  const [witData, setWitData] = useState<WitComponentValues>(defaultWit)
  const [shortCode, setShortCode] = useState<string | undefined>('')
  const { userCode, setUserCode } = useUserCodeContext()
  const { selectedLocale } = useLayoutContext()
  const [createWit] = useWitCreateMutation()

  const { refetch: refetchWit, loading: witLoading } = useWitQuery({
    skip: !userCode,
    fetchPolicy: 'no-cache',
    variables: {
      userCode: userCode ?? '',
    },
    onCompleted: (data) => {
      if (data?.wit) {
        setWitData(mapToWitComponentValues(data.wit))
      } else {
        console.warn('no wit data content for', userCode)
      }
    },
    onError: (error) => {
      console.error('error loading wit data for user code', userCode)
    },
  })

  const [createNewUserCodeContext] = useCreateUserCodeMutation({
    variables: { data: {} },
    fetchPolicy: 'network-only',
    // skip: userCode !== null && userCode.length > 0,
    onCompleted: (data) => {
      if (userCode) {
        return
      }
      if (data && data.createUserCode) {
        setUserCode(data.createUserCode.code)
        setWitData(defaultWit)
      } else {
        console.error('tried to create new user code but got nothing')
      }
    },
    onError: (error) => console.error(error),
  })

  const updateWit = async (witResultData: WitResultData) => {
    if (userCode != null && witData.shortCode !== witResultData.shortCode) {
      const witInput = mapToWitInput(witResultData, userCode)
      await createWit({
        variables: {
          createWitData: witInput,
        },
      })
      // set to force a re-render
      setWitData({
        shortCode: witResultData.shortCode,
        socials: witResultData.socials,
        values: witResultData.values,
      })
    }
  }

  useEffect(() => {
    if (!userCode) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ; (async () => {
        await createNewUserCodeContext()
      })()
    }
  })

  useEffect(() => {
    setShortCode(witData?.shortCode)
    let cancelled = false
    const fetchWitAgain = async () => {
      if (!cancelled && userCode && userCode.length > 0) {
        await refetchWit()
      }
    }
    fetchWitAgain()
    return () => {
      cancelled = true
    }
  }, [witData, refetchWit, userCode])

  const [matomoTracker, setMatomoTracker] = useState<MatomoEventTracker | undefined>()
  const { cookieConsentIsGiven } = React.useContext(CookieContext)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (cookieConsentIsGiven?.('matomo')) {
        setMatomoTracker(window['Matomo']?.getTracker())
      }
    }
  }, [cookieConsentIsGiven])

  if (witLoading || userCode === null) {
    return <LoadingSpinner></LoadingSpinner>
  }

  const witDataMapped = {
    newuser: userCode == null || witData.shortCode === '',
    language: selectedLocale,
    values: witData.values,
    socials: witData.socials,
    shortCode: witData.shortCode,
  }

  return (
    <MatomoTrackerContext.Provider value={{ matomoTracker }}>
      <Wit
        key={shortCode}
        witData={witDataMapped}
        onWitFinished={updateWit}
        witPDFUrl={`${process.env.GATSBY_FILES_FRONTEND_API_ENDPOINT}/pdf/wit/${userCode}/${selectedLocale}`}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCreatePdf={
          async () => void 0
          /*
          REPLACED with HREF see HOW-547
          if (!userCode) {
            return
          }
          const result = await fetch(
            `${process.env.GATSBY_FILES_FRONTEND_API_ENDPOINT}/pdf/wit/${userCode}/${selectedLocale}`,
          )
          if (!result.ok) {
            return
          }
          const blobPart = await result.blob()
          const blob = new Blob([blobPart], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const newWindow = window?.open(url, '_blank')
          newWindow?.focus()
        }*/
        }
        onShowOffers={async () => {
          const filter = { witMatch: 0.8 }
          const encodedFilters = encodeURIComponent(JSON.stringify(filter))
          const params = new Map([
            ['t', 'TrainingOffers'],
            ['f', encodedFilters],
          ])
          const slug = localizedSlug(selectedLocale, '/suche')
          await navigateWithParams(slug, true, params)
        }}
        matomoEventTracker={matomoTracker}
      />
    </MatomoTrackerContext.Provider>
  )
}

export default WitWrapper
